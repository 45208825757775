$lanacional: (
  'primario': #455A64,
  'secundario': #7e848c,
  'terciario': #448AFF,
  'cuarto': #0cc2aa,
  'white': #fff,
  'black': #474a4f
);
$shades: (
  "black": #000000,
  "white": #FFFFFF,
  "transparent":  transparent
);
$red: (
  "accent-2": #FF5252
);
$grey: (
  "darken-2": #616161
);
$blue: (
  "base": #2196F3
);
$green: (
  "base": #4CAF50
);
$colors: (
  "lanacional": $lanacional,
  "shades": $shades,
  "blue": $blue,
  "red": $red,
  "grey": $grey,
  "green": $green
);

// Color Classes

@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    .color-#{$color_name}-#{$color_type} {
      background-color: $color_value !important;
    }
    .color-#{$color_name}-#{$color_type}-text {
      color: $color_value !important;
    }
  }
}

// Shade classes
@each $color, $color_value in $shades {
  .#{$color} {
    background-color: $color_value !important;
  }
  .#{$color}-text {
    color: $color_value !important;
  }
}


// usage: color("name_of_color", "type_of_color")
// to avoid to repeating map-get($colors, ...)

@function color($color, $type) {
  @if map-has-key($colors, $color) {
    $curr_color: map-get($colors, $color);
    @if map-has-key($curr_color, $type) {
      @return map-get($curr_color, $type);
    }
  }
  @warn "Unknown `#{name}` in $colors.";
  @return null;
}
