.modal-mask {
  position: fixed;
  z-index: 1100;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  -webkit-overflow-scrolling: touch;
  display: table;
  padding-left: 60px;
  transition: opacity .3s ease;
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    width: 555px;
    margin: 0px auto;
    padding: 0;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }
  .modal-header{
    padding: 0;
    margin: 0;
    border: 0;
    .modal-title{
      margin: 0;
      background-color: #474a4f;
      padding: 15px 15px;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
    }
  }
  .modal-body {
    position: relative;
    margin: 0;
    padding: 15px;

    .table_edit_question{
      > thead{
        > tr{
          > th{
            background-color: #353535;
            color: #fff;
          }
        }
      }
      >tbody{
        > tr{
          >td{
            padding: 4px;
            vertical-align: middle;
            border-right: 1px solid #ddd;
            .ui-textbox{
              margin: 0px;
            }
          }
        }
      }
    }
    .table_type_question{
      > thead{
        > tr{
          > th{
            background-color: #353535;
            color: #fff;
          }
        }
      }
      >tbody{
        > tr{
          >td{
            padding: 3px;
            vertical-align: middle;
            border-right: 1px solid #ddd;
            &:first-child{
              text-align: center;
            }
            .ui-textbox{
              margin: 0;
            }
          }
        }
      }
    }
  }
  .modal-footer{
    position: relative;
    padding: 10px;
    text-align: center;
    width: 100%;
    border: 0;
    color: #fff;
  }
  &.modal-client{
    padding: 0;
    .modal-body{
      padding: 30px;
      .message{
        font-size: 18px;
        font-weight: 500;
      }
    }
    .modal-container {
      width: 450px;
    }
    .modal-footer{
      .ui-button{
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        /*&.color-lanacional-style-primario{
          background-color: transparent;
          border: 2px solid #ff5252;
          color: #ff5252;
        }
        &.color-lanacional-style-terciario{
          background-color: transparent;
          border: 2px solid #448AFF;
          color: #448AFF;
        }*/
      }
      .nav_footer{
        width: 100%;
        position: relative;
        > li{
          width: 50%;
          float: left;
          .ui-button{
            border-radius: 0;
            width: 100%;
            &.color-lanacional-style-primario{
              background-color: transparent;
              border: 2px solid #ff5252;
              color: #ff5252;
            }
            &.color-lanacional-style-terciario{
              background-color: transparent;
              border: 2px solid #448AFF;
              color: #448AFF;
            }
          }
        }
      }
    }
  }
  &.modal-profile{
    position: fixed;
    .modal-container{
      width: 235px;
      border-radius: 6px;
      border: 0;
      .modal-body{
        text-align: center;
        padding: 15px 10px;
        img{
          width: 120px;
          height: 120px;
          margin: 0 auto;
          box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
          border-radius: 50%;
          overflow: hidden;
        }
        h3{
          font-size: 20px;
          margin: 15px 0 8px;
        }
        p{
          margin: 0;
          font-size: 12px;
          & + p{
            margin-top: 4px;
          }
        }
        .btn-save{
          height: 24px;
          width: 24px;
          border-radius: 50%;
          position: absolute;
          right: -12px;
          top: -12px;
          line-height: 24px;
          min-width: 0;
          padding: 0;
          i{
            font-size: 18px;
          }
        }
      }
    }
  }
}
.modal-enter, .modal-leave {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media (min-width: 768px){
  .modal-mask{
    &.modal-client{
      padding: 0;
    }
  }
}
@media (min-width: 992px){
  .modal-mask{
    padding-left: 180px;
    &.modal-client{
      padding: 0;
    }

  }
}