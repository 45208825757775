/*
 * Section Person
 */
.section-person{
  padding: 30px 0;
  position: absolute;
  top: 80px;
  overflow-y: auto;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  .width-person{
    position: relative;
    width: 555px;
    margin: 0 auto;
    &.bg-white{
      background-color: #fff;
      padding: 0;
      box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);
    }
    .name-person{
      margin: 0 0 25px;
    }
    .search-person{
      width: 100%;
      position: relative;
      .form-search{
        padding: 0;
        .form-group{
          margin: 0;
        }
      }
    }
    .list-search{
      //width: 100%;
      margin: 15px -15px 0;
      .ui-button{
        color: #fff;
        text-transform: capitalize;
        margin-top: 6px;
      }
      .btn-height{
        height: 40px;
        margin-top: 6px;
        color: #fff;
        text-transform: capitalize;
        font-weight: 400;
      }
    }
  }
  .table{
    >tbody{
      >tr{
        >td{
          padding: 4px 8px;
          vertical-align: middle;
          .ui-textbox{
            margin: 0;
            .ui-textbox-feedback{
              display: none;
            }
          }
          .ui-icon-button{
            color: #fff;
            width: 30px;
            height: 30px;
            margin: 0 auto;
            .ui-icon{
              font-size: 18px;
            }
          }
          &.td-restore{
            .ui-icon-button{
              display: block;
            }
          }
          .ui-checkbox{
            margin: 0 auto;
            &.checked{
              .ui-checkbox-checkmark{
                &:before {
                  background: #448AFF;
                  border-color: #448AFF;
                }
              }
            }
            .ui-checkbox-label-text{
              display: none;
            }
          }
        }
      }
    }
  }
}
