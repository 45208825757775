.section-chart{
  padding: 30px 0;
  position: absolute;
  top: 80px;
  overflow-y: auto;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  .width-chart{
    position: relative;
    width: 555px;
    margin: 0 auto;
    &.bg-white{
      background-color: #fff;
      padding: 0;
      box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);
    }
  }
  .width-filter{
    position: relative;
    width: 98%;
    margin: 1%;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #efefef;
    .ui-alert-body{
      margin: 12px 0 0;
      padding: 8px 16px;
      min-height: 40px;
    }
  }
  .width-chart{
    position: relative;
    width: 98%;
    margin: 1%;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #efefef;
    .ui-select{
      max-width: 80px;
    }
  }
}
@media (min-width: 992px){
  .section-chart{
    .width-filter{
      width: 700px;
      margin: 0 auto;
      padding: 15px;
      &.col-4_filter{
        width: 94%;
      }
    }
  }
}