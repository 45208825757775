.bg-log{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image:url(../img/home1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.65;
  filter: alpha(opacity=65);
}
.login-table{
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,.35);
  height: 100%;
  width: 100%;
  display: table;
  .login-cell{
    vertical-align: middle;
    display: table-cell;
  }
}
.row_sign{
  margin: 0 -7px;
}
.option_register{
  width: 100%;
  margin: 10px 0 0;
  > li{
    width: 100%;
    float: left;
    padding: 0 7px;
    button{
      width: 100%;
      color: #fff;
      border: 1px solid rgba(0,0,0,0.2);
      padding: 10px 0;
      display: block;
      font-size: 14px;
      border-radius: 0;
      transition: all .3s ease-in-out;
      &.btn-google{
        background-color: #dd4b39;
        &:hover{
          background-color: #c23321;
        }
      }
      &.btn-facebook{
        background-color: #3b5998;
        &:hover{
          background-color: #2d4373;
        }
      }
    }
    & + li{
      margin-top: 10px;
    }
  }
}
.login-container{
  position: relative;
  z-index: 10;
  width: 500px;
  margin: 0 auto;
  padding: 0;
  .nav_log{
    width: 100%;
    margin: 20px 0 0;
    > li{
      width: 50%;
      float: left;
      &:nth-child(1){
        button{
          float: left;
        }
      }
      &:nth-child(2){
        button{
          float: right;
        }
      }
      button{
        background-color: transparent;
        border: 0;
        padding: 0;
        font-weight: 300;
        padding: 0;
        display: block;
        color: rgba(255,255,255,.75);
        border-radius: 0;
        cursor: pointer;
        &:hover, &:focus{
          color: rgba(255,255,255,1);
          outline: 0;
          text-decoration: none;
        }
        /*&.btn-login{
          background-color: #59599F;
        }
        &.btn-register{
          background-color: #A15598;
        }*/
      }
    }
  }
  .login-header{
    text-align: center;
    color: #fff;
    width: 350px;
    margin: 0 auto;
    display: block;
    margin-bottom: 5px;
    img{
      width: 100%;
      height: auto;
      margin: 0 auto;
    }
    h1{
      margin: 15px 0 0;
      font-weight: 200;
    }
    .content_social{
      margin: 15px -5px 0;
      .nav_social_button{
        >li{
          padding: 0 5px;
          width: 50%;
          float: left;
          a{
            background-color: rgba(255,255,255,.6);
            padding: 0;
            height: 30px;
            border-radius: 30px;
            line-height: 30px;
            text-align: center;
            width: 100%;
            display: block;
            color: #272634;
            &:hover, &:focus, &.v-link-active{
              text-decoration: none;
              background-color: rgba(255,255,255,.85);
            }
          }
        }
      }
    }
  }
  .login-body{
    padding: 10px;
    min-height: 140px;
    color: rgba(230, 230, 230, 0.9);
    width: 300px;
    margin: 0 auto;
    overflow: hidden;
    transition: all .4s ease;
    .text_sign{
      margin: 5px 0 0;
      text-align: center;
      color: #ABA8AE;
      font-size: 13px;
      button{
        color: #fff;
        background-color: transparent;
        border: 0;
        padding: 0;
        &:hover, &:focus{
          outline: 0;
          text-decoration: none;
        }
      }
    }
    .ui-alert-body{
      &.error{
        background-color: rgba(244, 67, 54, 0.78);
      }
      &.success{
        background-color: rgba(76, 175, 80, 0.78);
      }
      .ui-alert-icon, a{
        color: #fff;
      }
      .ui-alert-text{
        color: #fff;
      }
    }
    .ui-button {
      margin-top: 15px;
      width: 100%;
      //background-color: transparent !important;
      //border: 1px solid rgba(255,255,255,.9);
      color: #fff;
      .ui-progress-circular .ui-progress-circular-indeterminate-path.black{
        //stroke: #fff;
      }
    }
    .panel-default{
      .form-horizontal .form-group{
        margin: 0;
      }
    }
    /*.ui-textbox:hover:not(.disabled):not(.invalid) .ui-textbox-input, .ui-textbox:hover:not(.disabled):not(.invalid) .ui-textbox-textarea{
      border-color: rgba(255,255,255,.6);
    }
    .ui-textbox.active:not(.disabled):not(.invalid) .ui-textbox-input, .ui-textbox.active:not(.disabled):not(.invalid) .ui-textbox-textarea{
      border-color: rgba(255,255,255,.9);
    }*/
    .ui-textbox{
      color: #fff;
      .ui-textbox-icon-wrapper{
        color: #fff;
        margin-right: 10px;
        .ui-textbox-icon{
          color: rgba(255,255,255,.75);
        }
      }
    }
    .ui-textbox-input{
      font-weight: 300;
      color: #fff;
      border-color: rgba(255,255,255,.9);
    }
    .btn-submit{
      background-color: transparent;
      border: 1px solid #fff;
      width: 100%;
      padding: 0;
      height: 50px;
      will-change: box-shadow,transform;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      display: block;
      color: #fff;
      font-weight: 300;
      margin-top: 25px;
      border-radius: 0;
      cursor: pointer;
      &:hover, &:focus{
        outline: 0;
        text-decoration: none;
      }
    }
  }
}


/*.login-container .login-body .form-control::-moz-placeholder {
  color: rgba(230, 230, 230, 0.9);
  opacity: 1;
}
.login-container .login-body .form-control:-ms-input-placeholder {
  color: rgba(230, 230, 230, 0.9);
}
.login-container .login-body .form-control::-webkit-input-placeholder {
  color: rgba(230, 230, 230, 0.9);
}
*/