.container-layout{
  position: absolute;
  width: 100%;
  height: 100%;
}
body{
  background-color: #F4F5F7;
}
.content-layout{
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
  .navbar-background{
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 4;
    visibility: hidden;
    transition-property: background-color;
    transition-duration: .2s;
    &.active{
      background-color: rgba(0,0,0,.5);
      visibility: visible;
    }
  }
  .content-aside{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    // width: 180px;
    width: 60px;
    height: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background-color: #303e45;//#2a2b3c;
    // transform: translateX(-180px);
    transform: translateX(0);
    transform-style: preserve-3d;
    will-change: transform;
    transition-duration: .2s;
    transition-property: -webkit-transform;
    transition-property: transform;
    color: #fff;
    overflow: visible;
    overflow-y: auto;
    z-index: 5;
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12);
    &.active{
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  .content-main{
    -ms-flex: 0 1 auto;
    display: inline-block;
    overflow-y: hidden;
    overflow-x: hidden;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
    bottom: 0;
    position: absolute;
    top: 0;
    // width: 100%;
    // margin-left: 0;
    margin-top: 0;
    width: calc(100% - 60px);
    margin-left: 60px;
    // background-color: #ECF1F5;
    transition: all .2s;
  }
}
@media (min-width: 768px) {
  .content-layout{
    .content-aside{
      width: 60px;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    .content-main{
      width: calc(100% - 60px);
      margin-left: 60px;
    }
  }
}
@media (min-width: 992px) {
  .content-layout{
    .content-aside{
      width: 180px;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    .content-main{
      width: calc(100% - 180px);
      margin-left: 180px;
    }
  }
}

.header-user{
  height: 60px;
  padding: 0 15px;
  text-align: center;
  width: 100%;
  z-index: 120;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0,0,0,.2);
  .b-left{
    float: left;
  }
  .b-right{
    float: right;
    margin-right: -15px;
  }
  .nav_option_layout{
    position: relative;
    margin-top: 9px;
    > li{
      float: left;
      & + li{
        margin-left: 10px;
      }
    }
  }
  .anda-logo{
    width: 50px;
    height: 50px;
    margin-top: 10px;
    float: left;
    display: block;
    img{
      width: 100%;
      display: block;
      height: auto;
    }
    &:hover, &:focus{
      cursor: pointer;
      outline: 0;
      background-color: transparent;
    }
  }
  .nav_opt{
    > li{
      position: relative;
      height: 60px;
      float: left;
      & + li{
        margin-left: 5px;
      }
      .btn-option-chat{
        margin-top: 6px;
      }
      .nav_menu{
        position: absolute;
        float: right;
        padding: 0;
        min-width: 140px;
        max-width: 250px;
        background-color: #fff;
        border: 1px solid #D9DEE4;
        top: 60px;
        right: 0;
        > li{
          height: auto;
          width: 100%;
          a,button{
            color: #000;
            background-color: transparent;
            width: 100%;
            display: block;
            text-align: left;
            padding: 8px 15px 8px 25px;
            border: 0;
            &:hover,&:focus{
              outline: 0;
              background-color: #f5f5f5;
            }
          }
        }
      }
    }
  }
  .picture-user{
    position: relative;
    display: inline-block;
    *display: inline;
    border: 0;
    float: right;
    padding: 0;
    height: 20px;
    overflow: hidden;
    vertical-align: middle;
    color: #858598;
    margin: 20px 5px 0 0;

    .img_profile{
      display: inline-block;
      $himg: 30px;
      padding: 0;
      border-radius: 50%;
      width: $himg;
      height: $himg;
      overflow: hidden;
      background-color: transparent;
      z-index: 9;
      text-align: center;
      margin: 0 auto;
      &.not_image{
        border: 2px solid #858598;
        padding-top: 2px;
      }
      i{
        font-size: 20px;
      }
      img{
        width: $himg;
        height: $himg;
        display: block;
      }
    }
    .name-user{
      display: block;
      font-weight: 700;
      margin-left: 6px;
      position: relative;
      vertical-align: middle;
      //top: -10px;
      i{
        font-size: 16px;
        position: relative;
        float: right;
        margin-top: 2px;
        margin-left: 5px;
      }
    }
    &:hover{
      cursor: pointer;
      outline: 0;
      color: #03A9F4;
      .img_profile{
        color: #858598;
      }
      //background-color: rgb(63, 73, 99);
    }
  }
  /*.name-user{
    margin: 9px 0 0;
    color: #fff;
    font-weight: 300;
    font-size: 17px;
  }*/
  .email-user{
    color: #eee;
    font-weight: 300;
    font-size: 12px;
    margin: 7px 0 0;
    i{
      position: relative;
      float: none;
      top: 4px;
    }
  }
}
.header-logo{
  width: 100%;
  padding: 5px;
  height: 60px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
  .logo_img {
    display: block;
    height: 50px;
    width: 100%;
    background: {
      position: center;
      repeat: no-repeat;
      size: contain;
      image: url(../img/logo_responsive.png)
    }
  }
}
.nav_list-user{
  width: 100%;
  padding: 0;
  h4{
    margin: 0 0 10px 10px;
    color: #fff;
    font-size: 17px;
  }
  .nav-option_style{
    width: 100%;
    > li{
      position: relative;
      float: none;
      width: 100%;
      transition: all .3s;
      min-height: 50px;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      & + li{
        /*margin-top: 1px;
        &:after{
          position: absolute;
          content: "";
          width: 60%;
          left: 20%;
          top: -1px;
          height: 1px;
          background-color: rgba(255,255,255,.3);
        }*/
      }
      a{
        width: 100%;
        padding: 9px 10px;
        margin: 0;
        color: rgba(255,255,255,.7);
        text-decoration: none;
        font-weight: 300;
        font-size: 14px;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        .icon_a{
          display: block;
          text-align: center;
          height: 40px;
          width: 40px;
          float: left;
          border-radius: 2px;
          color: rgba(255,255,255,.7);
          background-color: rgba(0,0,0,.31);
          i{
            font-size: 24px;
            height: 40px;
            width: 40px;
            display: block; 
            line-height: 40px;
            cursor: inherit;
            margin: 0 auto;
          }
        }
        .name_a{
          display: none;
          margin-left: 10px;
        }
        &:hover, &:focus{
          background-color: rgba(0,0,0,.1);
          outline: 0;
          color: #fff;
          .icon_a{
            color: #fff;
          }
        }
        &.v-link-active{
          background-color: #0cc2aa;
          color: #fff;
          outline: 0;
          .icon_a{
            background-color: rgba(255, 255, 255, 0.31);
            color: #fff;
          }
        }
      }
    }
  }
}
.footer-contact{
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: 300;
  p{
    margin: 0;
  }
}
@media (min-width: 768px) {
  .nav_list-user{
    .nav-option_style{
      >li{
        a{
          i{
            font-size: 24px;
            height: 40px;
            width: 40px;
            line-height: 40px;
            margin: 0 auto;
          }
          .name_a{
            display: none;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .header-logo{
    .logo_img {
      background: {
        image: url(../img/logo_nacional.png)
      }
    }
  }
  .nav_list-user{
    .nav-option_style{
      >li{
        a{
          i{
            font-size: 20px;
            height: 32px;
            width: 32px;
            line-height: 32px;
            margin-right: 4px;
            margin-left: -4px;
          }
          .name_a{
            margin-left: 10px;
            display: inline-block;
          }
        }
      }
    }
  }
}
.header-section{
  width: 100%;
  position: relative;
  top: 0;
  z-index: 100;
  left: 0;
  height: 80px;
  //line-height: 65px;
  display: block;
  padding: 26px 15px 0;
  background:{
    image: url(../img/header-bg.png);
    color: #0cc2aa;
    size: cover;
  };
  // background-color: #fff;
  // box-shadow: 0 2px 7px 0 #C5D5E4, 0 2px 7px 0 #C5D5E4;
  h2{
    margin: 0;
    font-size: 28px;
    display: inline-block;
    *display: inline;
    font-weight: 400;
    padding: 0;
    color: #fff;
  }
  span{
    // display: inline-block;
    display: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-right: 15px;
    font-size: 30px;
    text-align: center;
    position: relative;
    top: 4px;
  }
  .timer_test{
    display: block;
    position: absolute;
    right: 15px;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    top: 50%;
    margin-top: -20px;
    i{
      font-size: 26px;
    }
  }
  .btn-icon-more{
    color: #fff;
    position: absolute;
    right: 15px;
    bottom: -28px;
  }
  .buttons-options{
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -15px;
    .ui-button{
      height: 30px;
      padding: 0 10px;
      line-height: 30px;
    }
  }
}
@media (min-width: 768px) {
  .header-section{
    span{
      display: none;
    }
  }
}

.ui-fab{
  .ui-icon{
    display: block;
    margin: 0 auto;
  }
}