/*
 * Section Contact List
 */
.section-users{
  padding: 30px 0;
  position: absolute;
  top: 80px;
  overflow-y: auto;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  .width-users{
    position: relative;
    width: 98%;
    background-color: #fff;
    margin: 0 auto;
    padding: 0;
    padding: 15px;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);
    &.width-assign{
      width: 100%;
    }
    &.min-height{
      min-height: 400px;
    }
    .timer_test{
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 16px;
    }
    .b_search{
      width: 400px;
      margin: 0 auto;
    }
    .load_messages{
      margin-top: 15px;
      width: 50px;
      height: 50px;
      display: block;
      margin: 0 auto;
    }
    .button-search{
      width: 100%;
      display: block;
      position: relative;
      margin-bottom: 10px;
      .ui-button{
        height: 30px;
        display: block;
        line-height: 30px;
        padding: 0 15px;
        margin: 0 auto;
        color: #fff;
      }
    }
  }
  .table{
    >tbody{
      >tr{
        >td{
          padding: 4px 8px;
          vertical-align: middle;
          &.assign-evaluation{
            .ui-button{
              font-size: 12px;
              color: #fff;
              height: 28px;
              width: 100%;
              display: block;
              line-height: 28px;
              padding: 0 8px;
              & + .ui-button{
                margin-top: 2px;
              }
            }
          }
          .ui-textbox{
            margin: 0;
            .ui-textbox-feedback{
              display: none;
            }
          }
          .ui-icon-button{
            color: #fff;
            width: 30px;
            height: 30px;
            margin: 0 auto;
            .ui-icon{
              font-size: 18px;
            }
          }
          &.td-restore{
            .ui-icon-button{
              display: block;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 550px){
  .section-contactlist{
    .width-contact{
      
    }
  }
}
@media (min-width: 730px) {
  .section-contactlist{
    padding: 40px 0;
    
  }
}
@media (min-width: 1170px){
  .section-contactlist{
    
  }
}

.pagination{
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
  > li {
    display: inline;
    &:first-child > a,
    &:first-child > span {
      margin-left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child > a,
    &:last-child > span {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &.active{
      a, span{
        background-color: #455A64;
        color: #fff;
      }
    }
    > a, 
    > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #455A64;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;
    }
  }
}