/*
 * Section Person
 */
.section-client{
  width: 100%;
  max-width: 100%;
  padding: 45px 0;
  position: absolute;
  overflow-y: auto;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #fdfdfd;
  background: {
    //image: url(../img/bg_client.jpg);
    position: relative;
    size: cover;
    repeat: no-repeat;
  }
  .signOut-client{
    position: absolute;
    right: 15px;
    height: 40px;
    top: 15px;
    .ui-icon-button{
      // color: #fff;
      color: rgba(69, 90, 100, 0.35) !important;
    }
    .nav_opt{
      position: absolute;
      float: right;
      padding: 0;
      min-width: 140px;
      max-width: 250px;
      background-color: #fff;
      border: 1px solid #D9DEE4;
      top: 40px;
      right: 0;
      > li{
        height: auto;
        width: 100%;
        a,button{
          color: #000;
          background-color: transparent;
          width: 100%;
          display: block;
          text-align: left;
          padding: 8px 15px 8px 25px;
          border: 0;
          &:hover,&:focus{
            outline: 0;
            background-color: #f5f5f5;
          }
        }
      }
    }
  }
  .container-client{
    position: relative;
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
    .logo{
      max-width: 350px;
      max-height: 250px;
      display: block;
      position: relative;
      margin: 0 auto;
    }
    .title_gnrl{
      font-size: 18px;
      margin: 10px 0 5px;
      & + p{
        font-size: 14px;
      }
    }
    .list_menu{
      position: relative;
      width: 380px;
      margin: 15px auto 0;
      .ui-button{
        color: #fff;
      }
      .item_menu{
        $hicon: 55px;
        position: relative;
        width: 100%;
        height: $hicon + 15px;
        padding: 0;
        padding-left: $hicon + 55px;
        .head_item{
          position: absolute;
          width: $hicon + 15px;
          height: $hicon + 15px;
          top: 0;
          left: 0;
          i{
            font-size: $hicon + 15px;
          }
          .item_label{
            font-weight: 600;
            padding: 17px 0px;
          }
        }
        .body_item{
          position: relative;
          padding-top: 8px;
          .nav_face{
            position: relative;
            width: 100%;
            > li{
              width: $hicon;
              height: $hicon;
              display: block;
              float: left;
              background:{
                position: center;
                repeat: no-repeat;
              }
              transition: all .1s ease;
              & + li{
                margin-left: 10px;
              }
              &:hover, &:focus{
                cursor: pointer;
              }
              &.face1{
                background-image: url(../img/smal1-temp.png);
                &.active{
                  background-image: url(../img/smal1-select.png);
                }
              }
              &.face2{
                background-image: url(../img/smal2-temp.png);
                &.active{
                  background-image: url(../img/smal2-select.png);
                }
              }
              &.face3{
                background-image: url(../img/smal3-temp.png);
                &.active{
                  background-image: url(../img/smal3-select.png);
                }
              }
              &.face4{
                background-image: url(../img/smal4-temp.png);
                &.active{
                  background-image: url(../img/smal4-select.png);
                }
              }
            }
          }
        }
        & + .item_menu{
          margin-top: 15px;
        }
      }
    }
  }
}