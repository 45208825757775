@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
       url(../fonts/MaterialIcons-Regular.woff) format('woff'),
       url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
  &.md-18{
    font-size: 18px;
  }
  &.md-24{
    font-size: 24px;
    height: 24px;
    line-height: 24px;
  }
  &.md-36{
    font-size: 36px;
    height: 36px;
    line-height: 36px;
  }
  &.md-48{
    font-size: 48px;
    height: 48px;
    line-height: 48px;
  }
  &.md-60{
    font-size: 60px;
    height: 60px;
    line-height: 60px;
  }
}
.md-spin{
  .material-icons{
    -webkit-animation:fa-spin 2s infinite linear;
    animation:fa-spin 2s infinite linear
  }
}
@-webkit-keyframes fa-spin{
  0%{
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg);
  }
  100%{
    -webkit-transform:rotate(359deg);
    transform:rotate(359deg);
  }
}

@keyframes fa-spin{
  0%{
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg)
  }
  100%{
    -webkit-transform:rotate(359deg);
    transform:rotate(359deg);
  }
}
body{
  overflow-x: hidden;
}
.preload-app{
  width: 100%;
  margin: 0 auto;
  .ui-progress-linear{
    background-color: rgba(89, 89, 159, 0.4) !important;
    .ui-progress-linear-indeterminate{
      background-color: #59599f !important;
    }
  }
}