
@import './components/keen-ui.scss';
@import './components/Animate.scss';
@import './components/Mixin.scss';
@import './components/Modals.scss';
@import './components/Bootstrap.scss';
@import './components/Color.scss';
@import './components/MaterialIcons.scss';
@import './components/DatePicker.scss';
@import './components/LayoutBase.scss';
@import './components/Login.scss';
@import './components/Client.scss';
@import './components/Users.scss';
@import './components/Person.scss';
@import './components/Chart.scss';

.bgTransition{  
  -webkit-animation-duration: .4s;
          animation-duration: .4s;
  position: absolute;
  overflow-y: hidden;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F4F5F7;
}
.bg-white{
  background-color: #fff;
  box-shadow: 0 8px 17px 0 #C5D5E4, 0 6px 20px 0 #C5D5E4;
}
/*
 * Css extra
 */
.nav_menu_form{
  display: inline-block;
  *display: inline;
  zoom: 1;
  > li{
    //height: 36px;
    float: left;
    overflow: hidden;
    color: #fff;
    /*a{
      padding: 0 15px; 
    }*/
    & + li{
      margin-left: 5px;
    }
  }
}

.btn-logout{
  background-color: #00bfa5;
  border: 0;
  color: #fff;
  padding: 16px;
  display: block;
  width: 100%;
  margin: 0 auto;
}
.btn-width{
  width: 100%;
}
