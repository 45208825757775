.ui-alert {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  line-height: 1.4em;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.ui-alert a {
  text-decoration: none;
}
.ui-alert a:hover,
.ui-alert a:focus {
  text-decoration: underline;
}
.ui-alert .ui-alert-close-button {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: -8px;
  margin-top: -4px;
  margin-bottom: -4px;
  color: rgba(0,0,0,0.38);
  margin-left: 8px;
}
.ui-alert .ui-alert-close-button:not([disabled]):hover,
body[modality="keyboard"] .ui-alert .ui-alert-close-button:focus {
  color: rgba(0,0,0,0.8);
}
.ui-alert .ui-alert-close-button .ui-icon {
  font-size: 18px;
  margin: 0;
}
.ui-alert-body {
  width: 100%;
  min-height: 52px;
  padding: 12px 16px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.ui-alert-body.error {
  background-color: rgba(244,67,54,0.12);
}
.ui-alert-body.error a {
  color: #f44336;
}
.ui-alert-body.error .ui-alert-icon {
  color: #f44336;
}
.ui-alert-body.success {
  background-color: rgba(76,175,80,0.12);
}
.ui-alert-body.success a {
  color: #4caf50;
}
.ui-alert-body.success .ui-alert-icon {
  color: #4caf50;
}
.ui-alert-body.info {
  background-color: rgba(33,150,243,0.12);
}
.ui-alert-body.info a {
  color: #2196f3;
}
.ui-alert-body.info .ui-alert-icon {
  color: #2196f3;
}
.ui-alert-body.warning {
  background-color: rgba(255,152,0,0.12);
}
.ui-alert-body.warning a {
  color: #ff9800;
}
.ui-alert-body.warning .ui-alert-icon {
  color: #ff9800;
}
.ui-alert-icon {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  margin-right: 12px;
}
.ui-alert-text {
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  color: rgba(0,0,0,0.75);
}
.ui-alert-toggle-transition {
  transition: all 0.3s ease;
  margin-top: 0;
  margin-bottom: 12px;
}
.ui-alert-toggle-enter,
.ui-alert-toggle-leave {
  margin-top: -52px;
  opacity: 0;
  margin-bottom: 0;
}
.ui-icon {
  font-size: 24px;
  width: 1em;
  height: 1em;
  display: inline-block;
  cursor: inherit;
  vertical-align: middle;
}
.drop-element {
  position: absolute;
  display: none;
  z-index: 60;
  max-width: 100%;
  max-height: 100%;
  transition: opacity 0.2s ease;
  opacity: 0;
}
.drop-element,
.drop-element:after,
.drop-element:before,
.drop-element *,
.drop-element *:after,
.drop-element *:before {
  box-sizing: border-box;
}
.drop-element.drop-open {
  display: block;
}
.drop-element.drop-after-open {
  opacity: 1;
}
.ui-icon-button {
  background: none;
  outline: none;
  border: none;
  position: relative;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.ui-icon-button::-moz-focus-inner {
  border: 0;
}
.ui-icon-button[disabled] {
  opacity: 0.6;
}
.ui-icon-button:not([disabled]) {
  cursor: pointer;
}
.ui-icon-button .ui-dropdown-menu {
  display: none;
}
.ui-icon-button-normal.color-primary,
.ui-icon-button-normal.color-accent,
.ui-icon-button-normal.color-success,
.ui-icon-button-normal.color-warning,
.ui-icon-button-normal.color-danger {
  color: #fff;
}
.ui-icon-button-normal.color-primary .ui-ripple-ink .ripple.held,
.ui-icon-button-normal.color-accent .ui-ripple-ink .ripple.held,
.ui-icon-button-normal.color-success .ui-ripple-ink .ripple.held,
.ui-icon-button-normal.color-warning .ui-ripple-ink .ripple.held,
.ui-icon-button-normal.color-danger .ui-ripple-ink .ripple.held {
  opacity: 0.7;
}
.ui-icon-button-normal.color-default {
  background-color: #eee;
}
.ui-icon-button-normal.color-default:hover:not([disabled]),
.ui-icon-button-normal.color-default.dropdown-open {
  background-color: #dcdcdc;
}
body[modality="keyboard"] .ui-icon-button-normal.color-default:focus {
  background-color: #bebebe;
}
.ui-icon-button-normal.color-default .ui-ripple-ink .ripple.held {
  opacity: 0.2;
}
.ui-icon-button-normal.color-default .ui-icon-button-icon {
  color: rgba(0,0,0,0.87);
}
.ui-icon-button-normal.color-primary {
  background-color: #2196f3;
}
.ui-icon-button-normal.color-primary:hover:not([disabled]),
.ui-icon-button-normal.color-primary.dropdown-open {
  background-color: #0c81df;
}
body[modality="keyboard"] .ui-icon-button-normal.color-primary:focus {
  background-color: #0b72c4;
}
.ui-icon-button-normal.color-accent {
  background-color: #d500f9;
}
.ui-icon-button-normal.color-accent:hover:not([disabled]),
.ui-icon-button-normal.color-accent.dropdown-open {
  background-color: #b500d4;
}
body[modality="keyboard"] .ui-icon-button-normal.color-accent:focus {
  background-color: #a000bb;
}
.ui-icon-button-normal.color-success {
  background-color: #4caf50;
}
.ui-icon-button-normal.color-success:hover:not([disabled]),
.ui-icon-button-normal.color-success.dropdown-open {
  background-color: #419544;
}
body[modality="keyboard"] .ui-icon-button-normal.color-success:focus {
  background-color: #39833c;
}
.ui-icon-button-normal.color-warning {
  background-color: #ff9800;
}
.ui-icon-button-normal.color-warning:hover:not([disabled]),
.ui-icon-button-normal.color-warning.dropdown-open {
  background-color: #d98100;
}
body[modality="keyboard"] .ui-icon-button-normal.color-warning:focus {
  background-color: #bf7200;
}
.ui-icon-button-normal.color-danger {
  background-color: #f44336;
}
.ui-icon-button-normal.color-danger:hover:not([disabled]),
.ui-icon-button-normal.color-danger.dropdown-open {
  background-color: #f01d0d;
}
body[modality="keyboard"] .ui-icon-button-normal.color-danger:focus {
  background-color: #d4190c;
}
.ui-icon-button-flat.color-default:hover:not([disabled]),
.ui-icon-button-flat.color-primary:hover:not([disabled]),
.ui-icon-button-flat.color-accent:hover:not([disabled]),
.ui-icon-button-flat.color-success:hover:not([disabled]),
.ui-icon-button-flat.color-warning:hover:not([disabled]),
.ui-icon-button-flat.color-danger:hover:not([disabled]),
.ui-icon-button-flat.color-default.dropdown-open,
.ui-icon-button-flat.color-primary.dropdown-open,
.ui-icon-button-flat.color-accent.dropdown-open,
.ui-icon-button-flat.color-success.dropdown-open,
.ui-icon-button-flat.color-warning.dropdown-open,
.ui-icon-button-flat.color-danger.dropdown-open {
  background-color: #e7e7e7;
}
.ui-icon-button-flat.color-default {
  color: rgba(0,0,0,0.87);
}
body[modality="keyboard"] .ui-icon-button-flat.color-default:focus {
  border: 2px solid #b3b3b3;
}
.ui-icon-button-flat.color-default .ui-icon-button-icon {
  color: rgba(0,0,0,0.87);
}
.ui-icon-button-flat.color-primary {
  color: #2196f3;
}
body[modality="keyboard"] .ui-icon-button-flat.color-primary:focus {
  border: 2px solid #2196f3;
}
.ui-icon-button-flat.color-primary .ui-icon-button-icon {
  color: #2196f3;
}
.ui-icon-button-flat.color-accent {
  color: #d500f9;
}
body[modality="keyboard"] .ui-icon-button-flat.color-accent:focus {
  border: 2px solid #d500f9;
}
.ui-icon-button-flat.color-accent .ui-icon-button-icon {
  color: #d500f9;
}
.ui-icon-button-flat.color-success {
  color: #43a047;
}
body[modality="keyboard"] .ui-icon-button-flat.color-success:focus {
  border: 2px solid #43a047;
}
.ui-icon-button-flat.color-success .ui-icon-button-icon {
  color: #43a047;
}
.ui-icon-button-flat.color-warning {
  color: #ff9800;
}
body[modality="keyboard"] .ui-icon-button-flat.color-warning:focus {
  border: 2px solid #ff9800;
}
.ui-icon-button-flat.color-warning .ui-icon-button-icon {
  color: #ff9800;
}
.ui-icon-button-flat.color-danger {
  color: #f44336;
}
body[modality="keyboard"] .ui-icon-button-flat.color-danger:focus {
  border: 2px solid #f44336;
}
.ui-icon-button-flat.color-danger .ui-icon-button-icon {
  color: #f44336;
}
.ui-icon-button-clear {
  background-color: transparent;
}
body[modality="keyboard"] .ui-icon-button-clear:focus,
.ui-icon-button-clear:hover:not([disabled]),
.ui-icon-button-clear.dropdown-open {
  background-color: rgba(0,0,0,0.1);
}
.ui-icon-button-clear.color-white {
  color: rgba(0,0,0,0.54);
}
body[modality="keyboard"] .ui-icon-button-clear.color-white:focus {
  border: 2px solid rgba(255,255,255,0.8);
}
.ui-icon-button-clear.color-white .ui-icon-button-icon {
  color: #fff;
}
.ui-icon-button-clear.color-black {
  color: rgba(0,0,0,0.54);
}
body[modality="keyboard"] .ui-icon-button-clear.color-black:focus {
  border: 2px solid rgba(0,0,0,0.25);
}
.ui-icon-button-clear.color-black .ui-icon-button-icon {
  color: rgba(0,0,0,0.54);
}
.ui-icon-button-icon {
  width: 100%;
  height: initial;
}
.ui-menu {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 4px 0;
  outline: none;
  list-style: none;
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.3), 0 4px 5px 0 rgba(0,0,0,0.15), 0 1px 10px 0 rgba(0,0,0,0.13);
  min-width: 168px;
  max-width: 272px;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.ui-menu.has-secondary-text {
  min-width: 208px;
  max-width: 304px;
}
.ui-menu-option {
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  cursor: default;
}
.ui-menu-option-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ui-menu-option {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  position: relative;
  display: block;
  height: 40px;
}
.ui-menu-option.divider {
  display: block;
  width: 100%;
  margin: 6px 0;
  padding: 0;
  height: 1px;
  background-color: rgba(0,0,0,0.08);
}
.ui-menu-option:not(.divider) {
  width: 100%;
  text-decoration: none;
  color: rgba(0,0,0,0.87);
  font-size: 14px;
  font-weight: normal;
  outline: none;
}
.ui-menu-option:not(.divider):hover:not(.disabled) {
  background-color: rgba(0,0,0,0.06);
}
body[modality="keyboard"] .ui-menu-option:not(.divider):focus {
  background-color: rgba(0,0,0,0.1);
}
.ui-menu-option:not(.divider).disabled {
  opacity: 0.5;
  color: rgba(0,0,0,0.54);
}
.ui-menu-option:not(.divider).disabled .ui-menu-option-secondary-text {
  color: rgba(0,0,0,0.54);
}
.ui-menu-option:not(.divider):not(.disabled) {
  cursor: pointer;
}
.ui-menu-option-content.ui-menu-default {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px 16px;
}
.ui-menu-option-icon {
  margin-right: 16px;
  font-size: 18px;
  color: rgba(0,0,0,0.54);
}
.ui-menu-option-text {
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.ui-menu-option-secondary-text {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  margin-left: 4px;
  font-size: 13px;
  color: rgba(0,0,0,0.38);
}
.ui-ripple-ink {
  display: block;
  overflow: hidden;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}
.ui-ripple-ink .ripple {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 50%;
  background-color: currentColor;
  background-clip: padding-box;
  opacity: 0.2;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  transition: opacity 0.4s ease-out, -webkit-transform 0.4s ease-out;
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
  transition: transform 0.4s ease-out, opacity 0.4s ease-out, -webkit-transform 0.4s ease-out;
}
.ui-ripple-ink .ripple.held {
  opacity: 0.4;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
.ui-ripple-ink .ripple.done {
  opacity: 0 !important;
}
.ui-popover {
  padding: 16px;
  outline: none;
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.3), 0 4px 5px 0 rgba(0,0,0,0.15), 0 1px 10px 0 rgba(0,0,0,0.13);
}
.ui-progress-circular {
  position: relative;
}
.ui-progress-circular .ui-progress-circular-determinate {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.ui-progress-circular .ui-progress-circular-determinate .ui-progress-circular-determinate-path {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.3s ease;
}
.ui-progress-circular .ui-progress-circular-determinate .ui-progress-circular-determinate-path.primary,
.ui-progress-circular .ui-progress-circular-determinate .ui-progress-circular-determinate-path.multi-color {
  stroke: #2196f3;
}
.ui-progress-circular .ui-progress-circular-determinate .ui-progress-circular-determinate-path.accent {
  stroke: #d500f9;
}
.ui-progress-circular .ui-progress-circular-determinate .ui-progress-circular-determinate-path.black {
  stroke: #212121;
}
.ui-progress-circular .ui-progress-circular-determinate .ui-progress-circular-determinate-path.white {
  stroke: #fff;
}
.ui-progress-circular .ui-progress-circular-indeterminate {
  -webkit-animation: ui-progress-circular-rotate 1.4s linear infinite;
          animation: ui-progress-circular-rotate 1.4s linear infinite;
  -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.ui-progress-circular .ui-progress-circular-indeterminate-path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  -webkit-animation: ui-progress-circular-dash 1.4s ease-in-out infinite;
          animation: ui-progress-circular-dash 1.4s ease-in-out infinite;
}
.ui-progress-circular .ui-progress-circular-indeterminate-path.multi-color {
  -webkit-animation: ui-progress-circular-dash 1.4s ease-in-out infinite, ui-progress-circular-color 6s ease-in-out infinite;
          animation: ui-progress-circular-dash 1.4s ease-in-out infinite, ui-progress-circular-color 6s ease-in-out infinite;
}
.ui-progress-circular .ui-progress-circular-indeterminate-path.primary {
  stroke: #2196f3;
}
.ui-progress-circular .ui-progress-circular-indeterminate-path.accent {
  stroke: #d500f9;
}
.ui-progress-circular .ui-progress-circular-indeterminate-path.black {
  stroke: #212121;
}
.ui-progress-circular .ui-progress-circular-indeterminate-path.white {
  stroke: #fff;
}
.ui-progress-circular-toggle-transition {
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}
.ui-progress-circular-toggle-enter,
.ui-progress-circular-toggle-leave {
  opacity: 0;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
}
@-webkit-keyframes ui-progress-circular-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes ui-progress-circular-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes ui-progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes ui-progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@-webkit-keyframes ui-progress-circular-color {
  100%, 0% {
    stroke: #f44336;
  }
  40% {
    stroke: #2196f3;
  }
  66% {
    stroke: #4caf50;
  }
  80%, 90% {
    stroke: #ff9800;
  }
}
@keyframes ui-progress-circular-color {
  100%, 0% {
    stroke: #f44336;
  }
  40% {
    stroke: #2196f3;
  }
  66% {
    stroke: #4caf50;
  }
  80%, 90% {
    stroke: #ff9800;
  }
}
.tooltip-element,
.tooltip-element:after,
.tooltip-element:before,
.tooltip-element *,
.tooltip-element *:after,
.tooltip-element *:before {
  box-sizing: border-box;
}
.tooltip-element {
  position: absolute;
  display: none;
  transition: opacity 3s ease;
  opacity: 0;
}
.tooltip-element.tooltip-open {
  display: block;
  opacity: 1;
}
.ui-tooltip {
  line-height: 1;
}
.tooltip-element.ui-tooltip-theme {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  z-index: 70;
}
.tooltip-element.ui-tooltip-theme .tooltip-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  border-radius: 2px;
  padding: 0 8px;
  height: 26px;
  font-family: inherit;
  font-size: 13px;
  line-height: 1;
  background: #212121;
  opacity: 0.9;
  color: #fff;
}
.tooltip-element.ui-tooltip-theme.tooltip-element-attached-bottom.tooltip-element-attached-center .tooltip-content {
  margin-bottom: 4px;
}
.tooltip-element.ui-tooltip-theme.tooltip-element-attached-top.tooltip-element-attached-center .tooltip-content {
  margin-top: 4px;
}
.tooltip-element.ui-tooltip-theme.tooltip-element-attached-right.tooltip-element-attached-middle .tooltip-content {
  margin-right: 4px;
}
.tooltip-element.ui-tooltip-theme.tooltip-element-attached-left.tooltip-element-attached-middle .tooltip-content {
  margin-left: 4px;
}
.tooltip-element.ui-tooltip-theme.tooltip-element-attached-top.tooltip-element-attached-left.tooltip-target-attached-bottom .tooltip-content {
  margin-top: 4px;
}
.tooltip-element.ui-tooltip-theme.tooltip-element-attached-top.tooltip-element-attached-right.tooltip-target-attached-bottom .tooltip-content {
  margin-top: 4px;
}
.tooltip-element.ui-tooltip-theme.tooltip-element-attached-bottom.tooltip-element-attached-left.tooltip-target-attached-top .tooltip-content {
  margin-bottom: 4px;
}
.tooltip-element.ui-tooltip-theme.tooltip-element-attached-bottom.tooltip-element-attached-right.tooltip-target-attached-top .tooltip-content {
  margin-bottom: 4px;
}
.tooltip-element.ui-tooltip-theme.tooltip-element-attached-top.tooltip-element-attached-right.tooltip-target-attached-left .tooltip-content {
  margin-right: 4px;
}
.tooltip-element.ui-tooltip-theme.tooltip-element-attached-top.tooltip-element-attached-left.tooltip-target-attached-right .tooltip-content {
  margin-left: 4px;
}
.tooltip-element.ui-tooltip-theme.tooltip-element-attached-bottom.tooltip-element-attached-right.tooltip-target-attached-left .tooltip-content {
  margin-right: 4px;
}
.tooltip-element.ui-tooltip-theme.tooltip-element-attached-bottom.tooltip-element-attached-left.tooltip-target-attached-right .tooltip-content {
  margin-left: 4px;
}
.ui-autocomplete {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-bottom: 12px;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.ui-autocomplete:hover:not(.disabled) .ui-autocomplete-label-text {
  color: rgba(0,0,0,0.65);
}
.ui-autocomplete:hover:not(.disabled) .ui-autocomplete-input {
  border-bottom-color: rgba(0,0,0,0.22);
}
.ui-autocomplete.active:not(.disabled) .ui-autocomplete-label-text,
.ui-autocomplete.active:not(.disabled) .ui-autocomplete-icon {
  color: #2196f3;
}
.ui-autocomplete.active:not(.disabled) .ui-autocomplete-input {
  border-bottom-width: 2px;
  border-bottom-color: #2196f3;
}
.ui-autocomplete.has-label .ui-autocomplete-icon-wrapper {
  padding-top: 20px;
}
.ui-autocomplete.has-label .ui-autocomplete-clear-button {
  top: 22px;
}
.ui-autocomplete.icon-right .ui-autocomplete-icon-wrapper {
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
  margin-left: 8px;
  margin-right: 0;
}
.ui-autocomplete.invalid:not(.disabled) .ui-autocomplete-label-text,
.ui-autocomplete.invalid:not(.disabled) .ui-autocomplete-icon {
  color: #f44336;
}
.ui-autocomplete.invalid:not(.disabled) .ui-autocomplete-input {
  border-bottom-color: #f44336;
}
.ui-autocomplete.disabled .ui-autocomplete-input {
  color: rgba(0,0,0,0.38);
  border-bottom-style: dashed;
}
.ui-autocomplete.disabled .ui-autocomplete-icon {
  opacity: 0.6;
}
.ui-autocomplete.disabled .ui-autocomplete-feedback {
  opacity: 0.8;
}
.ui-autocomplete-label {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
}
.ui-autocomplete-icon-wrapper {
  height: 24px;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  margin-right: 12px;
  padding-top: 4px;
}
.ui-autocomplete-icon {
  color: rgba(0,0,0,0.54);
}
.ui-autocomplete-content {
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.ui-autocomplete-label-text {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 2px;
  color: rgba(0,0,0,0.54);
  transition: color 0.1s ease;
}
.ui-autocomplete-input {
  cursor: auto;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  width: 100%;
  height: 32px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0,0,0,0.12);
  transition: border 0.1s ease;
  color: rgba(26,26,26,0.87);
  font-weight: normal;
  font-size: 16px;
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.ui-autocomplete-input::-ms-clear {
  display: none;
}
.ui-autocomplete-clear-button {
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 6px;
  color: rgba(0,0,0,0.38);
  cursor: pointer;
}
.ui-autocomplete-clear-button:hover {
  color: rgba(0,0,0,0.54);
}
.ui-autocomplete-feedback {
  margin: 0;
  height: 20px;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  padding-top: 4px;
}
.ui-autocomplete-help-text {
  color: rgba(0,0,0,0.38);
  line-height: 1;
}
.ui-autocomplete-error-text {
  position: absolute;
  color: #f44336;
  line-height: 1;
}
.ui-autocomplete-feedback-toggle-transition {
  transition-property: opacity, margin-top;
  transition-duration: 0.3s;
  opacity: 1;
  margin-top: 0;
}
.ui-autocomplete-feedback-toggle-enter,
.ui-autocomplete-feedback-toggle-leave {
  opacity: 0;
  margin-top: -20px;
}
.ui-autocomplete-suggestions {
  min-width: 100%;
  display: block;
  position: absolute;
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
  list-style-type: none;
  box-shadow: 1px 2px 8px #757575;
  background-color: #fff;
  color: rgba(0,0,0,0.87);
  transition: left 0.1s ease-in-out;
  z-index: 60;
}
.ui-autocomplete-suggestion {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px 12px;
  font-weight: normal;
  font-size: 15px;
}
.ui-autocomplete-suggestion:hover {
  background-color: rgba(0,0,0,0.06);
}
.ui-autocomplete-suggestion.highlighted {
  background-color: rgba(0,0,0,0.1);
}
.ui-autocomplete-image {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.ui-autocomplete-image .image {
  width: 32px;
  height: 32px;
  background-size: cover;
  background-position: 50%;
  margin-right: 12px;
  border-radius: 50%;
}
.ui-button {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.71em;
  letter-spacing: 0.02em;
}
.drop-element {
  position: absolute;
  display: none;
  z-index: 60;
  max-width: 100%;
  max-height: 100%;
  transition: opacity 0.2s ease;
  opacity: 0;
}
.drop-element,
.drop-element:after,
.drop-element:before,
.drop-element *,
.drop-element *:after,
.drop-element *:before {
  box-sizing: border-box;
}
.drop-element.drop-open {
  display: block;
}
.drop-element.drop-after-open {
  opacity: 1;
}
.ui-button {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1;
  background: none;
  overflow: hidden;
  outline: none;
  border: none;
  position: relative;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  cursor: default;
  border-radius: 2px;
  padding: 8px 16px;
  min-width: 80px;
  height: 40px;
}
.ui-button::-moz-focus-inner {
  border: 0;
}
.ui-button.autofocus:focus,
body[modality="keyboard"] .ui-button:focus {
  outline-style: solid;
}
.ui-button[disabled] {
  opacity: 0.6;
}
.ui-button:not([disabled]) {
  cursor: pointer;
}
.ui-button .ui-button-dropdown-menu {
  display: none;
}
.ui-button .ui-button-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ui-button-raised {
  box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.2);
  transition: box-shadow 0.1s;
}
.ui-button-raised.autofocus:focus,
body[modality="keyboard"] .ui-button-raised:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0,0,0,0.22), 0 3px 6px rgba(0,0,0,0.3);
}
.ui-button-normal.autofocus:focus,
body[modality="keyboard"] .ui-button-normal:focus {
  outline-width: 2px;
  outline-offset: 2px;
}
.ui-button-normal.color-primary,
.ui-button-normal.color-accent,
.ui-button-normal.color-success,
.ui-button-normal.color-warning,
.ui-button-normal.color-danger {
  color: #fff;
}
.ui-button-normal.color-primary .ui-ripple-ink .ripple.held,
.ui-button-normal.color-accent .ui-ripple-ink .ripple.held,
.ui-button-normal.color-success .ui-ripple-ink .ripple.held,
.ui-button-normal.color-warning .ui-ripple-ink .ripple.held,
.ui-button-normal.color-danger .ui-ripple-ink .ripple.held {
  opacity: 0.7;
}
.ui-button-normal.color-default {
  color: rgba(0,0,0,0.87);
  background-color: #eee;
}
.ui-button-normal.color-default:hover:not([disabled]),
.ui-button-normal.color-default.dropdown-open {
  background-color: #dcdcdc;
}
.ui-button-normal.color-default.autofocus:focus,
body[modality="keyboard"] .ui-button-normal.color-default:focus {
  background-color: #cacaca;
  outline-color: #a7a7a7;
}
.ui-button-normal.color-default .ui-ripple-ink .ripple.held {
  opacity: 0.2;
}
.ui-button-normal.color-default .ui-button-icon,
.ui-button-normal.color-default .ui-button-dropdown-icon {
  color: rgba(0,0,0,0.54);
}
.ui-button-normal.color-primary {
  background-color: #2196f3;
}
.ui-button-normal.color-primary:hover:not([disabled]),
.ui-button-normal.color-primary.dropdown-open {
  background-color: #0c81df;
}
.ui-button-normal.color-primary.autofocus:focus,
body[modality="keyboard"] .ui-button-normal.color-primary:focus {
  background-color: #0b7ad1;
  outline-color: #0b7ad1;
}
.ui-button-normal.color-accent {
  background-color: #d500f9;
}
.ui-button-normal.color-accent:hover:not([disabled]),
.ui-button-normal.color-accent.dropdown-open {
  background-color: #b500d4;
}
.ui-button-normal.color-accent.autofocus:focus,
body[modality="keyboard"] .ui-button-normal.color-accent:focus {
  background-color: #aa00c7;
  outline-color: #aa00c7;
}
.ui-button-normal.color-success {
  background-color: #4caf50;
}
.ui-button-normal.color-success:hover:not([disabled]),
.ui-button-normal.color-success.dropdown-open {
  background-color: #419544;
}
.ui-button-normal.color-success.autofocus:focus,
body[modality="keyboard"] .ui-button-normal.color-success:focus {
  background-color: #3d8c40;
  outline-color: #3d8c40;
}
.ui-button-normal.color-warning {
  background-color: #ff9800;
}
.ui-button-normal.color-warning:hover:not([disabled]),
.ui-button-normal.color-warning.dropdown-open {
  background-color: #d98100;
}
.ui-button-normal.color-warning.autofocus:focus,
body[modality="keyboard"] .ui-button-normal.color-warning:focus {
  background-color: #cc7a00;
  outline-color: #cc7a00;
}
.ui-button-normal.color-danger {
  background-color: #f44336;
}
.ui-button-normal.color-danger:hover:not([disabled]),
.ui-button-normal.color-danger.dropdown-open {
  background-color: #f01d0d;
}
.ui-button-normal.color-danger.autofocus:focus,
body[modality="keyboard"] .ui-button-normal.color-danger:focus {
  background-color: #e21b0c;
  outline-color: #e21b0c;
}
.ui-button-flat {
  background-color: transparent;
}
.ui-button-flat:hover:not([disabled]),
.ui-button-flat.dropdown-open {
  background-color: #e7e7e7;
}
.ui-button-flat.autofocus:focus,
body[modality="keyboard"] .ui-button-flat:focus {
  outline-width: 2px;
  outline-offset: 0;
}
.ui-button-flat.color-default {
  color: rgba(0,0,0,0.87);
}
.ui-button-flat.color-default .ui-button-icon {
  color: rgba(0,0,0,0.54);
}
.ui-button-flat.color-default.autofocus:focus,
body[modality="keyboard"] .ui-button-flat.color-default:focus {
  outline-color: #b3b3b3;
}
.ui-button-flat.color-primary {
  color: #2196f3;
}
.ui-button-flat.color-accent {
  color: #d500f9;
}
.ui-button-flat.color-success {
  color: #43a047;
}
.ui-button-flat.color-warning {
  color: #ff9800;
}
.ui-button-flat.color-danger {
  color: #f44336;
}
.ui-button-icon {
  font-size: 18px;
  margin-right: 4px;
  margin-left: -4px;
}
.ui-button-icon.position-right {
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
  margin-left: 4px;
  margin-right: -4px;
}
.ui-button-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.ui-button-content.invisible {
  visibility: hidden;
}
.ui-button-dropdown-icon {
  font-size: 18px;
  margin-right: -8px;
  margin-left: 1px;
}
.ui-button-group {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.ui-checkbox {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
  margin-bottom: 12px;
  cursor: default;
  position: relative;
}
.ui-checkbox:not(.disabled):not(.checked):hover .ui-checkbox-checkmark:before,
.ui-checkbox:not(.disabled):not(.checked).active .ui-checkbox-checkmark:before {
  border-color: rgba(0,0,0,0.54);
}
.ui-checkbox:not(.disabled).checked:hover .ui-checkbox-checkmark:before,
.ui-checkbox:not(.disabled).checked.active .ui-checkbox-checkmark:before {
  background-color: #0c81df;
  border-color: #0c81df;
}
.ui-checkbox.checked .ui-checkbox-checkmark:before {
  background: #2196f3;
  border-color: #2196f3;
}
.ui-checkbox.checked .ui-checkbox-checkmark:after {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  opacity: 1;
}
.ui-checkbox.checked .ui-checkbox-focus-ring {
  background-color: rgba(33,150,243,0.12);
}
.ui-checkbox.label-left .ui-checkbox-label-text {
  margin-left: 0;
  margin-right: auto;
  -webkit-order: -1;
      -ms-flex-order: -1;
          order: -1;
}
.ui-checkbox.disabled .ui-checkbox-label-text {
  color: rgba(0,0,0,0.38);
}
.ui-checkbox.disabled .ui-checkbox-checkmark:before {
  border-color: rgba(0,0,0,0.26);
}
.ui-checkbox.disabled.checked .ui-checkbox-checkmark:before {
  border: none;
  background: rgba(0,0,0,0.26);
}
.ui-checkbox:not(.disabled) .ui-checkbox-label-text {
  cursor: pointer;
}
.ui-checkbox-label-text {
  margin-left: 8px;
  font-size: 15px;
}
.ui-checkbox-checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background: #fff;
}
.ui-checkbox-checkmark:before {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  border-radius: 2px;
  border: 2px solid rgba(0,0,0,0.38);
  transition: all 0.3s ease;
}
.ui-checkbox-checkmark:after {
  box-sizing: border-box;
  position: absolute;
  left: 7px;
  bottom: 5px;
  display: block;
  content: "";
  width: 6px;
  height: 13px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.3s ease;
  transition-delay: 0.1s;
  box-sizing: border-box;
}
.ui-checkbox-input {
  position: absolute;
  opacity: 0;
}
body[modality="keyboard"] .ui-checkbox-input:focus + .ui-checkbox-checkmark .ui-checkbox-focus-ring {
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
.ui-checkbox-focus-ring {
  position: absolute;
  width: 48px;
  height: 48px;
  background-color: rgba(0,0,0,0.1);
  margin-left: -14px;
  margin-top: -14px;
  border-radius: 50%;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0;
}
.ui-collapsible-header-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ui-collapsible {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  margin-bottom: 8px;
}
.ui-collapsible-header {
  position: relative;
  margin: 0;
  width: 100%;
  border: none;
  line-height: 1;
  text-align: left;
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 0;
  min-height: 48px;
  padding: 8px 16px;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  cursor: pointer;
  font-size: 16px;
  background-color: #eee;
}
.ui-collapsible-header:hover:not(.disabled),
body[modality="keyboard"] .ui-collapsible-header:focus {
  background-color: #e0e0e0;
}
.ui-collapsible-header.disabled {
  opacity: 0.6;
  cursor: default;
}
.ui-collapsible-header.disabled .ui-icon {
  cursor: default;
}
.ui-collapsible-header .ui-icon {
  cursor: pointer;
}
.ui-collapsible-header .ui-ripple-ink .ripple.held {
  opacity: 0.01;
}
.ui-collapsible-header-content {
  line-height: 1.25em;
}
.ui-collapsible-header-icon {
  margin-left: auto;
  margin-right: -4px;
  color: rgba(0,0,0,0.54);
}
.ui-collapsible-body-wrapper {
  overflow: hidden;
  height: initial;
}
.ui-collapsible-body {
  width: 100%;
  padding: 16px;
  display: block;
  border: 1px solid #eee;
  border-top: 0;
}
.ui-collapsible-toggle-transition {
  transition: height 0.2s ease;
}
.ui-collapsible-toggle-enter,
.ui-collapsible-toggle-leave {
  height: 0 !important;
}
.ui-confirm-message {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
}
.ui-modal {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.ui-modal.ui-modal-large .ui-modal-container {
  width: 848px;
}
.ui-modal.ui-modal-small .ui-modal-container {
  width: 320px;
}
body.ui-modal-open {
  overflow: hidden;
}
.ui-modal-mask {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: table;
  transition: opacity 0.2s ease;
}
.ui-modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.ui-modal-container {
  outline: none;
  width: 528px;
  margin: 0px auto;
  padding: 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.33);
  transition: all 0.2s ease;
  max-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}
.ui-modal-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 24px 24px 8px;
}
.ui-modal-header .ui-modal-header-text {
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  font-size: 22px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
  font-weight: normal;
}
.ui-modal-header .ui-modal-close-button {
  margin-top: -12px;
  margin-right: -8px;
  margin-left: auto;
}
.ui-modal-header .ui-modal-close-button:hover:not([disabled]) .ui-icon,
body[modality="keyboard"] .ui-modal-header .ui-modal-close-button:focus .ui-icon {
  color: rgba(0,0,0,0.8);
}
.ui-modal-header .ui-modal-close-button .ui-icon {
  font-size: 20px;
  color: rgba(0,0,0,0.4);
}
.ui-modal-header .ui-modal-close-button[disabled] {
  opacity: 0.5;
}
.ui-modal-body {
  padding: 16px 24px 24px;
}
.ui-modal-footer {
  margin-top: -8px;
  padding: 24px;
  padding-top: 8px;
}
.ui-modal-footer,
.ui-modal-footer [slot] {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.ui-modal-footer .ui-modal-footer-left,
.ui-modal-footer [slot].ui-modal-footer-left {
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.ui-modal-footer .ui-button {
  margin-left: 8px;
}
.ui-modal-footer .ui-button:first-child {
  margin-left: 0;
}
.ui-modal-fade-enter,
.ui-modal-fade-leave {
  opacity: 0;
}
.ui-modal-scale-enter,
.ui-modal-scale-leave {
  opacity: 0;
}
.ui-modal-scale-enter .ui-modal-container,
.ui-modal-scale-leave .ui-modal-container {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.ui-fab {
  position: relative;
  outline: none;
  border: none;
  z-index: 30;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2), 0 2px 10px 0 rgba(0,0,0,0.16);
  transition: box-shadow 0.3s ease;
}
.ui-fab::-moz-focus-inner {
  border: 0;
}
.ui-fab:hover:not([disabled]),
body[modality="keyboard"] .ui-fab:focus {
  box-shadow: 0 8px 17px 0 rgba(0,0,0,0.25), 0 6px 20px 0 rgba(0,0,0,0.2);
}
.ui-fab:not([disabled]) {
  cursor: pointer;
}
.ui-fab.color-default {
  background-color: #fff;
  color: rgba(0,0,0,0.54);
}
.ui-fab.color-default .ui-fab-icon {
  color: rgba(0,0,0,0.54);
}
.ui-fab.color-default .ui-ripple-ink .ripple.held {
  opacity: 0.2;
}
.ui-fab.color-primary,
.ui-fab.color-accent {
  color: #fff;
}
.ui-fab.color-primary .ui-fab-icon,
.ui-fab.color-accent .ui-fab-icon {
  color: #fff;
}
.ui-fab.color-primary .ui-ripple-ink .ripple.held,
.ui-fab.color-accent .ui-ripple-ink .ripple.held {
  opacity: 0.7;
}
.ui-fab.color-primary {
  background-color: #2196f3;
}
body[modality="keyboard"] .ui-fab.color-primary:focus {
  background-color: #0d89ec;
}
.ui-fab.color-accent {
  background-color: #d500f9;
}
body[modality="keyboard"] .ui-fab.color-accent:focus {
  background-color: #c000e0;
}
.ui-fab .ui-ripple-ink {
  border-radius: 50%;
}
.ui-fab-normal {
  width: 56px;
  height: 56px;
}
.ui-fab-mini {
  width: 40px;
  height: 40px;
}
.ui-fab-icon {
  margin: 0;
  width: 100%;
  height: initial;
}
.ui-preloader {
  position: relative;
  width: 100%;
}
.ui-preloader-progressbar {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #159756;
  -webkit-animation: ui-preloader-background linear 3s infinite;
          animation: ui-preloader-background linear 3s infinite;
  transition-property: opacity, padding-top;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.ui-preloader-progressbar.loading {
  opacity: 1;
  padding-top: 3px;
}
.ui-preloader-progressbar::before,
.ui-preloader-progressbar::after {
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 0;
  height: 3px;
  background: #afa;
  -webkit-animation: ui-preloader-front linear 3s infinite;
          animation: ui-preloader-front linear 3s infinite;
  content: '';
}
.ui-preloader-progressbar::before {
  right: 50%;
}
.ui-preloader-progressbar::after {
  left: 50%;
}
@-webkit-keyframes ui-preloader-background {
  0%, 24.9% {
    background-color: #159756;
  }
  25%, 49.9% {
    background-color: #da4733;
  }
  50%, 74.9% {
    background-color: #3b78e7;
  }
  75%, 100% {
    background-color: #fdba2c;
  }
}
@keyframes ui-preloader-background {
  0%, 24.9% {
    background-color: #159756;
  }
  25%, 49.9% {
    background-color: #da4733;
  }
  50%, 74.9% {
    background-color: #3b78e7;
  }
  75%, 100% {
    background-color: #fdba2c;
  }
}
@-webkit-keyframes ui-preloader-front {
  0% {
    width: 0;
    background-color: #da4733;
  }
  24.9% {
    width: 50%;
    background-color: #da4733;
  }
  25% {
    width: 0;
    background-color: #3b78e7;
  }
  49.9% {
    width: 50%;
    background-color: #3b78e7;
  }
  50% {
    width: 0;
    background-color: #fdba2c;
  }
  74.9% {
    width: 50%;
    background-color: #fdba2c;
  }
  75% {
    width: 0%;
    background-color: #159756;
  }
  100% {
    width: 50%;
    background-color: #159756;
  }
}
@keyframes ui-preloader-front {
  0% {
    width: 0;
    background-color: #da4733;
  }
  24.9% {
    width: 50%;
    background-color: #da4733;
  }
  25% {
    width: 0;
    background-color: #3b78e7;
  }
  49.9% {
    width: 50%;
    background-color: #3b78e7;
  }
  50% {
    width: 0;
    background-color: #fdba2c;
  }
  74.9% {
    width: 50%;
    background-color: #fdba2c;
  }
  75% {
    width: 0%;
    background-color: #159756;
  }
  100% {
    width: 50%;
    background-color: #159756;
  }
}
.ui-progress-linear {
  position: relative;
  overflow: hidden;
  display: block;
  height: 4px;
  width: 100%;
  transition-property: height, opacity;
  transition-timing-function: ease;
  transition-duration: 0.3s;
}
.ui-progress-linear.color-primary {
  background-color: rgba(33,150,243,0.4);
}
.ui-progress-linear.color-primary .ui-progress-linear-determinate,
.ui-progress-linear.color-primary .ui-progress-linear-indeterminate {
  background-color: #2196f3;
}
.ui-progress-linear.color-accent {
  background-color: rgba(213,0,249,0.4);
}
.ui-progress-linear.color-accent .ui-progress-linear-determinate,
.ui-progress-linear.color-accent .ui-progress-linear-indeterminate {
  background-color: #d500f9;
}
.ui-progress-linear.color-black {
  background-color: rgba(97,97,97,0.4);
}
.ui-progress-linear.color-black .ui-progress-linear-determinate,
.ui-progress-linear.color-black .ui-progress-linear-indeterminate {
  background-color: #616161;
}
.ui-progress-linear.color-white {
  background-color: rgba(255,255,255,0.4);
}
.ui-progress-linear.color-white .ui-progress-linear-determinate,
.ui-progress-linear.color-white .ui-progress-linear-indeterminate {
  background-color: #fff;
}
.ui-progress-linear-determinate {
  transition: width 0.3s linear;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
}
.ui-progress-linear-indeterminate:before {
  background-color: inherit;
  will-change: left, right;
  position: absolute;
  content: '';
  bottom: 0;
  top: 0;
  left: 0;
  -webkit-animation: ui-progress-linear-indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
          animation: ui-progress-linear-indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.ui-progress-linear-indeterminate:after {
  background-color: inherit;
  will-change: left, right;
  position: absolute;
  content: '';
  bottom: 0;
  top: 0;
  left: 0;
  -webkit-animation: ui-progress-linear-indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
          animation: ui-progress-linear-indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s;
}
.ui-progress-linear-toggle-leave,
.ui-progress-linear-toggle-enter {
  opacity: 0;
  height: 0;
}
@-webkit-keyframes ui-progress-linear-indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes ui-progress-linear-indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@-webkit-keyframes ui-progress-linear-indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes ui-progress-linear-indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
.ui-radio {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 20px;
  font-size: 15px;
  margin: 0;
}
.ui-radio:hover:not(.disabled) .ui-radio-input:not(:checked) ~ .ui-radio-border {
  border: 2px solid rgba(0,0,0,0.54);
}
.ui-radio.label-left .ui-radio-label-text {
  -webkit-order: -1;
      -ms-flex-order: -1;
          order: -1;
  margin-right: auto;
  margin-left: 0;
}
.ui-radio.disabled {
  opacity: 0.5;
}
.ui-radio:not(.disabled) .ui-radio-label-text {
  cursor: pointer;
}
.ui-radio-input-wrapper {
  position: relative;
  width: 20px;
  height: 20px;
}
.ui-radio-input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  margin: 0;
  padding: 0;
  position: absolute;
  height: 1px;
  width: 1px;
  left: 0;
  top: 0;
  opacity: 0;
}
.ui-radio-input:checked ~ .ui-radio-border {
  border-color: #0cc2aa !important;
}
.ui-radio-input:checked ~ .ui-radio-inner-dot {
  background-color: #0cc2aa !important;
  -webkit-transform: scale(0.5);
      -ms-transform: scale(0.5);
          transform: scale(0.5);
  opacity: 1;
  z-index: 0;
}
.ui-radio-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid rgba(0,0,0,0.38);
  background-color: transparent;
  transition: border-color 0.2s;
}
.ui-radio-inner-dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.38);
  opacity: 0;
  z-index: -1;
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
  transition-property: opacity, background-color, -webkit-transform;
  transition-property: transform, opacity, background-color;
  transition-property: transform, opacity, background-color, -webkit-transform;
  transition-duration: 0.3s;
}
.ui-radio-label-text {
  margin-left: 16px;
  font-size: 15px;
}
.ui-radio-group-help-text {
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  cursor: default;
}
.ui-radio-group {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.ui-radio-group:not(.disabled):hover .ui-radio-group-label {
  color: rgba(0,0,0,0.65);
}
.ui-radio-group:not(.disabled).active .ui-radio-group-label {
  color: #0cc2aa !important;
}
.ui-radio-group.vertical .ui-radio-group-options-wrapper {
  height: auto;
  margin-top: 8px;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.ui-radio-group.vertical .ui-radio-group-options-wrapper .ui-radio {
  width: 100%;
  margin-left: 0;
  margin-bottom: 16px;
}
.ui-radio-group.disabled .ui-radio-group-feedback {
  opacity: 0.8;
}
.ui-radio-group .ui-radio {
  margin-left: 24px;
}
.ui-radio-group .ui-radio:first-child {
  margin-left: 0;
}
.ui-radio-group-label {
  font-size: 14px;
  color: rgba(0,0,0,0.54);
  transition: color 0.1s ease;
}
.ui-radio-group-options-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.ui-radio-group-feedback {
  height: 20px;
  overflow: hidden;
  padding-top: 4px;
  position: relative;
  font-size: 14px;
}
.ui-radio-group-help-text {
  color: rgba(0,0,0,0.38);
  line-height: 1.2;
}
.ui-rating {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  outline: none;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.ui-rating:hover:not(.disabled) .ui-rating-label {
  color: rgba(0,0,0,0.65);
}
.ui-rating.active:not(.disabled) .ui-rating-label {
  color: #2196f3;
}
body[modality="keyboard"] .ui-rating:focus .ui-rating-icon-icon {
  color: rgba(0,0,0,0.54);
}
body[modality="keyboard"] .ui-rating:focus .ui-rating-icon-icon.selected {
  color: rgba(0,0,0,0.54);
}
body[modality="keyboard"] .ui-rating:focus .ui-rating-icon-icon.filled {
  color: #0b7ad1;
}
.ui-rating.preview .ui-rating-icon-icon {
  cursor: pointer;
}
.ui-rating.preview .ui-rating-icon-icon.selected {
  color: rgba(0,0,0,0.38);
}
.ui-rating.preview .ui-rating-icon-icon.filled {
  color: #2196f3;
}
.ui-rating.disabled .ui-rating-icons-wrapper {
  opacity: 0.6;
}
.ui-rating.disabled .ui-rating-feedback {
  opacity: 0.8;
}
.ui-rating .ui-rating-icon-icon.selected {
  color: #2196f3;
}
.ui-rating-label {
  font-size: 14px;
  margin-bottom: 4px;
  color: rgba(0,0,0,0.54);
  transition: color 0.1s ease;
}
.ui-rating-icons-wrapper {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.ui-rating-feedback {
  height: 20px;
  overflow: hidden;
  padding-top: 4px;
  position: relative;
  font-size: 14px;
}
.ui-rating-help-text {
  color: rgba(0,0,0,0.38);
  line-height: 1;
}
.ui-rating-feedback-toggle-transition {
  transition-property: opacity, margin-top;
  transition-duration: 0.3s;
  margin-top: 0;
  opacity: 1;
}
.ui-rating-feedback-toggle-enter,
.ui-rating-feedback-toggle-leave {
  margin-top: -20px;
  opacity: 0;
}
.ui-rating-icon {
  cursor: default;
}
.ui-rating-icon .ui-icon {
  color: rgba(0,0,0,0.38);
}
.ui-rating-preview {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  width: auto;
}
.ui-rating-preview .ui-rating-icon-icon.selected {
  color: #2196f3;
}
.ui-select {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-bottom: 12px;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  outline: none;
}
.ui-select:hover:not(.disabled) .ui-select-label-text {
  color: rgba(0,0,0,0.75);
}
.ui-select:hover:not(.disabled) .ui-select-display {
  border-bottom-color: rgba(0,0,0,0.22);
}
.ui-select:hover:not(.disabled) .ui-select-dropdown-icon {
  color: rgba(0,0,0,0.54);
}
.ui-select.active:not(.disabled) .ui-select-label-text,
.ui-select.active:not(.disabled) .ui-select-icon {
  color: #0cc2aa !important;
}
.ui-select.active:not(.disabled) .ui-select-display {
  border-bottom-width: 2px;
  border-bottom-color: #0cc2aa !important;
}
.ui-select.has-label .ui-select-icon-wrapper {
  padding-top: 20px;
}
.ui-select.has-label .ui-select-dropdown-icon {
  top: 20px;
}
.ui-select.icon-right .ui-select-icon-wrapper {
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
  margin-left: 8px;
  margin-right: 0;
}
.ui-select.invalid:not(.disabled) .ui-select-label-text,
.ui-select.invalid:not(.disabled) .ui-select-icon {
  color: #f44336;
}
.ui-select.invalid:not(.disabled) .ui-select-display {
  border-bottom-color: #f44336;
}
.ui-select.disabled .ui-select-display {
  cursor: default;
  color: rgba(0,0,0,0.38);
  border-bottom-style: dashed;
}
.ui-select.disabled .ui-select-dropdown-icon,
.ui-select.disabled .ui-select-value.placeholder {
  opacity: 0.6;
  color: rgba(0,0,0,0.38);
}
.ui-select.disabled .ui-select-icon {
  opacity: 0.6;
}
.ui-select.disabled .ui-select-feedback {
  opacity: 0.8;
}
.ui-select-icon-wrapper {
  height: 24px;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  margin-right: 12px;
  padding-top: 4px;
}
.ui-select-icon {
  color: rgba(0,0,0,0.54);
}
.ui-select-content {
  display: block;
  width: 100%;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.ui-select-label {
  outline: none;
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
}
.ui-select-label-text {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 2px;
  color: rgba(0,0,0,0.75);
  transition: color 0.1s ease;
}
.ui-select-display {
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 0;
  min-height: 32px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0,0,0,0.12);
  transition: border 0.1s ease;
  line-height: 1;
  color: rgba(26,26,26,0.87);
  font-weight: normal;
  font-size: 16px;
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.ui-select-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  line-height: 1.4;
}
.ui-select-value.placeholder {
  color: rgba(0,0,0,0.38);
}
.ui-select-dropdown-icon {
  margin-left: auto;
  margin-right: -4px;
  color: rgba(0,0,0,0.38);
}
.ui-select-dropdown {
  outline: none;
  width: 100%;
  min-width: 180px;
  display: block;
  position: absolute;
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
  list-style-type: none;
  box-shadow: 1px 2px 8px #757575;
  background-color: #fff;
  color: rgba(0,0,0,0.87);
  transition: left 0.1s ease-in-out;
  z-index: 60;
}
.ui-select-search-input {
  margin: 0;
  border: none;
  outline: none;
  background: none;
  padding: 0 12px;
  width: 100%;
  height: 42px;
  font-size: 15px;
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
.ui-select-search-input::-ms-clear {
  display: none;
}
.ui-select-search-spinner {
  position: absolute;
  top: 8px;
  right: 12px;
}
.ui-select-options {
  position: relative;
  display: block;
  min-width: 100%;
  max-height: 223px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: #fff;
  color: rgba(0,0,0,0.87);
}
.ui-select-no-results {
  padding: 8px 12px;
  font-size: 14px;
  color: rgba(0,0,0,0.54);
  width: 100%;
}
.ui-select-feedback {
  margin: 0;
  height: 20px;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  padding-top: 4px;
}
.ui-select-help-text {
  color: rgba(0,0,0,0.38);
  line-height: 1;
}
.ui-select-error-text {
  position: absolute;
  color: #f44336;
  line-height: 1;
}
.ui-select-feedback-toggle-transition {
  transition-property: opacity, margin-top;
  transition-duration: 0.3s;
  opacity: 1;
  margin-top: 0;
}
.ui-select-feedback-toggle-enter,
.ui-select-feedback-toggle-leave {
  opacity: 0;
  margin-top: -20px;
}
.ui-select-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ui-select-option {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  cursor: pointer;
  overflow: hidden;
}
.ui-select-option.selected {
  color: #0cc2aa !important;
  font-weight: 500;
}
.ui-select-option.selected .ui-select-option-checkbox {
  color: #2196f3;
}
.ui-select-option.highlighted {
  color: #fff !important;
  background-color: #0cceb4 !important;
}
.ui-select-option.highlighted .ui-select-option-checkbox {
  color: #fff;
}
.ui-select-option-content {
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 42px;
  padding: 6px 12px;
  font-weight: normal;
  font-size: 15px;
}
.ui-select-option-checkbox {
  color: rgba(0,0,0,0.54);
  margin-right: 8px;
}
.ui-select-image {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.ui-select-item-text {
  width: 0;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.ui-select-item-image {
  width: 32px;
  height: 32px;
  background-size: cover;
  background-position: 50%;
  margin-right: 12px;
  border-radius: 50%;
}
.ui-textbox {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 12px;
}
.ui-textbox:hover:not(.disabled):not(.invalid) .ui-textbox-label-text {
  color: rgba(0,0,0,0.75);
}
.ui-textbox:hover:not(.disabled):not(.invalid) .ui-textbox-input,
.ui-textbox:hover:not(.disabled):not(.invalid) .ui-textbox-textarea {
  border-bottom-color: rgba(0,0,0,0.22);
}
.ui-textbox.active:not(.disabled) .ui-textbox-input,
.ui-textbox.active:not(.disabled) .ui-textbox-textarea {
  border-bottom-width: 2px;
}
.ui-textbox.active:not(.disabled):not(.invalid) .ui-textbox-label-text,
.ui-textbox.active:not(.disabled):not(.invalid) .ui-textbox-icon {
  color: #0cc2aa !important;
}
.ui-textbox.active:not(.disabled):not(.invalid) .ui-textbox-input,
.ui-textbox.active:not(.disabled):not(.invalid) .ui-textbox-textarea {
  border-bottom-color: #0cc2aa !important;
}
.ui-textbox.has-label .ui-textbox-icon-wrapper {
  padding-top: 20px;
}
.ui-textbox.icon-right .ui-textbox-icon-wrapper {
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
  margin-left: 8px;
  margin-right: 0;
}
.ui-textbox.is-multi-line .ui-textbox-label-text {
  margin-bottom: 8px;
}
.ui-textbox.has-counter .ui-textbox-feedback {
  padding-right: 48px;
}
.ui-textbox.invalid .ui-textbox-label-text,
.ui-textbox.invalid .ui-textbox-icon,
.ui-textbox.invalid .ui-textbox-counter {
  color: #f44336;
}
.ui-textbox.invalid .ui-textbox-input,
.ui-textbox.invalid .ui-textbox-textarea {
  border-bottom-color: #f44336;
}
.ui-textbox.disabled .ui-textbox-input,
.ui-textbox.disabled .ui-textbox-textarea {
  color: rgba(0,0,0,0.38);
  border-bottom-style: dotted;
  border-bottom-width: 2px;
}
.ui-textbox.disabled .ui-textbox-icon {
  opacity: 0.6;
}
.ui-textbox.disabled .ui-textbox-feedback {
  opacity: 0.8;
}
.ui-textbox-label {
  width: 100%;
  margin: 0;
  padding: 0;
}
.ui-textbox-icon-wrapper {
  height: 24px;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  margin-right: 12px;
  padding-top: 4px;
}
.ui-textbox-icon {
  color: rgba(0,0,0,0.54);
}
.ui-textbox-content {
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.ui-textbox-label-text {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 2px;
  color: rgba(0,0,0,0.75);
  transition: color 0.1s ease;
}
.ui-textbox-input,
.ui-textbox-textarea {
  cursor: auto;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  display: block;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0,0,0,0.12);
  transition: border 0.1s ease;
  color: rgba(26,26,26,0.87);
  font-weight: normal;
  font-size: 16px;
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.ui-textbox-input {
  height: 32px;
}
.ui-textbox-textarea {
  resize: vertical;
  overflow-x: hidden;
  padding-bottom: 8px;
}
.ui-textbox-feedback {
  margin: 0;
  min-height: 20px;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  padding-top: 2px;
}
.ui-textbox-help-text,
.ui-textbox-counter {
  color: rgba(0,0,0,0.38);
  line-height: 1.2;
}
.ui-textbox-error-text {
  position: absolute;
  color: #f44336;
  line-height: 1.2;
}
.ui-textbox-counter {
  position: absolute;
  right: 0;
  top: 2px;
}
.ui-textbox-feedback-toggle-transition {
  transition-property: opacity, margin-top;
  transition-duration: 0.3s;
  margin-top: 0;
  opacity: 1;
}
.ui-textbox-feedback-toggle-enter,
.ui-textbox-feedback-toggle-leave {
  margin-top: -20px;
  opacity: 0;
}
.ui-toolbar {
  font-family: "Roboto", sans-serif;//Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 18px;
  position: relative;
}
.ui-toolbar:not(.ui-toolbar-raised):not(.ui-toolbar-colored) {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
.ui-toolbar.text-color-black {
  color: rgba(0,0,0,0.87);
}
.ui-toolbar.text-color-black .ui-toolbar-divider {
  border-left-color: rgba(0,0,0,0.15);
}
.ui-toolbar.text-color-white {
  color: #fff;
}
.ui-toolbar.text-color-white .ui-toolbar-divider {
  border-color: rgba(255,255,255,0.4);
}
.ui-toolbar .ui-icon-button {
  width: 48px;
  height: 48px;
}
.ui-toolbar .ui-toolbar-preloader {
  position: absolute;
  height: 3px;
  right: 0;
  left: 0;
  bottom: 0;
}
.ui-toolbar .ui-toolbar-preloader.position-top {
  top: 0;
}
.ui-toolbar-raised {
  box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.2);
}
.ui-toolbar-clear {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.ui-toolbar-default {
  background-color: #fff;
}
.ui-toolbar-colored {
  background-color: #2196f3;
}
.ui-toolbar-left {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
}
.ui-toolbar-nav-icon {
  margin-right: 8px;
}
.ui-toolbar-brand-text {
  padding-right: 8px;
}
.ui-toolbar-brand {
  min-width: 160px;
}
.ui-toolbar-brand-text {
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.ui-toolbar-center {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.ui-toolbar-divider {
  border-left-width: 1px;
  border-left-style: solid;
  height: 24px;
  margin-right: 24px;
}
.ui-toolbar-right {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  margin-left: auto;
}
.ui-toolbar-right [slot="actions"] {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

/*# sourceMappingURL=keen-ui.css.map*/